import Vue from 'vue'
import { BootstrapVue, IconsPlugin , LayoutPlugin, CardPlugin} from 'bootstrap-vue'
import App from './App.vue'

// Import Bootstrap and BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)
// The layout components such as <b-container>, <b-row>, <b-col>:
Vue.use(LayoutPlugin)
// The <b-card> along with all the <b-card-*> sub-components as a plugin:
Vue.use(CardPlugin)

Vue.config.productionTip = false

new Vue({
    render: h => h(App),
}).$mount('#app')
