<template>
    <footer class="py-5 bg-light">
        <b-container class="py-5 text-start">
            <b-row align-h="center">
                <b-col class="col-lg-5 mb-3">
                    <a class="d-inline-flex align-items-center mb-2 link-dark text-decoration-none" href="/" aria-label="Bootstrap">
                        <img src="../assets/logo.png" alt="Tether" width="40" height="40" class="d-block me-2">
                        <span class="fs-5 fw-bold">Tether</span>
                    </a>
                    <ul class="list-unstyled small text-muted">
                        <li class="mb-2">Disclosure: Some links on this blog are affiliate links, meaning we may earn a
                            commission when you click or make a purchase. The price remains the same for you whether you
                            use the affiliate link or directly visit the vendor's site. Your support through these links
                            is greatly appreciated!</li>
                    </ul>
                </b-col>
                <b-col class="col-4 col-lg-2 offset-lg-1 mb-3">
                    <h4 class="fw-bold">Links</h4>
                    <ul class="list-unstyled">
                        <li class="mb-2 fw-bolder"><a href="https://auth0.com/" target="blank">Auth0</a></li>
                        <li class="mb-2 fw-bolder"><a href="https://csa-iot.org/all-solutions/matter/" target="blank">Matter</a></li>
                        <li class="mb-2 fw-bolder"><a href="https://bandwagonhost.com/aff.php?aff=68042" target="blank">Bandwagon</a></li>
                    </ul>
                </b-col>
            </b-row>
        </b-container>
    </footer>
</template>

<script>
export default {
    
}
</script>

<style scoped>
ul.list-unstyled {
    list-style: none;
    padding: 0;
    margin: 0;
}

ul.list-unstyled li a {
    color: inherit;
    text-decoration: none;
}

ul.list-unstyled li a:hover {
    text-decoration: underline;
    color: rgb(78, 1, 146);
}
</style>
